import React from 'react'
import { render } from 'react-dom';
import Loader from './components/loader/loader';
import Customise from './components/customise/customise';
import './style/index.scss';
import './style/base.scss';
import { Settings } from './components/loader/settings';


/**
 * Initialises the customise module library.
 * @param {username:String, petname:String, petsci:String} customiseData 
 */
export function initialize(customiseData = {}, rootElem, loaderElem) {
  
  console.log('checking elements');
  console.log(rootElem);
  console.log(loaderElem);
  console.log('***************');

  const rootElement = document.getElementById(rootElem)
  console.log(rootElement);
  customiseData.loaderElem = loaderElem;
  render(
    <React.Fragment>
      <Customise customiseData={customiseData} />
    </React.Fragment>, rootElement
  );
  render(<Loader />, document.getElementById(loaderElem));
}

//initialize({username: 'powergame11', petname: 'PurplyYurble'});

//initialize({
  //username: 'powergame11', petname: 'AtomZafara', baseDomain: 'http://dev.neopets.com/amfphp/services/jss/apiservices.phtml', assetsDomain: 'http://images.neopets.com',
//})