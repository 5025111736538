//import { Settings } from './components/loader/settings';


export class LoaderService {
    constructor(loaderDiv) {
        this.element = document.getElementById(loaderDiv);
    }
    activeRequests = 0;

    show() {
        this.activeRequests++;

        if (this.activeRequests === 1) {
            if(this.element){
                this.element.style.display = 'inline-block';
            }
            document.body.style.overflow = 'hidden';
        }
    }

    hide() {
        if (this.activeRequests === 1) {
            if(this.element){
                this.element.style.display = 'none';
            }
            document.body.style.overflow = 'auto';
        }
        if (this.activeRequests > 0) {
            this.activeRequests--;
        }
    }
}
