import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import { LoaderService } from '../../loader_service';
import { ZoneDepth } from '../../utils/zoneDepth';

class CustomisePet extends React.Component {
  /**
   *Creates an instance of CustomisePet.
   * @param {Class props} props
   *
   */
  constructor(props) {
    super(props);
    this.loaderService = new LoaderService(props.customiseData.loaderElem);
    this.avatarElemName = "nppvma_petViewAvatarLayer" + this.props.customiseData.petname; 

    this.state = {
      manifest: [],
      intervals: [],
      showFiltersPopup: false,
      showFiltersPopup: false,
    }
    this.count = 0;
    this.destroyRegisteredAssetEvent = {};
    this.handleFileLoad = this.handleFileLoad.bind(this)
    this.handleCompleteTree = this.handleCompleteTree.bind(this)
    this.handleFileLoadTree = this.handleFileLoadTree.bind(this)
    this.handleError = this.handleError.bind(this)
    this.animateCount = 0;
    this.audioPlayer = {};
  }

  componentWillMount() {
    // this.getPet();
  }

  /**
  *LifeCycle method
  */
  componentDidMount() {
    $('.nppvma-petView .nppvma-avatarlayers').css('height', '100%')

  }

  async applyStaticAsset(finalObject, showAssetAppliedSuccessfullyToast) {
    var data = document.getElementById(this.avatarElemName);
    var node3 = document.createElement("img")
    node3.setAttribute('src', finalObject.static)
    node3.style.zIndex = finalObject.zIndex;
    node3.style.height = "100%";
    node3.id = finalObject.asset_id
    node3.style.width = "100% ";
    node3.style.position = "absolute";
    node3.draggable = false;
    node3.dataset.zindex = finalObject.zIndex;
    await data.appendChild(node3)
    this.loaderService.hide();
    if (showAssetAppliedSuccessfullyToast) {
      this.props.showAssetAppliedSuccessfullyToast(showAssetAppliedSuccessfullyToast)
    }
  }
  /**
   * removeAvataritemFromPet;
   * removes the applied image from the pet area
   *
   * @param {avatarData} object
   *
   */
  removeAvataritemFromPet = (assetRegistry) => {
    assetRegistry.map((avatarData) => {
      if (typeof this.destroyRegisteredAssetEvent[avatarData.asset_id] != "undefined" && this.destroyRegisteredAssetEvent[avatarData.asset_id] != null) {
        this.destroyRegisteredAssetEvent[avatarData.asset_id]();
        this.destroyRegisteredAssetEvent[avatarData.asset_id] = null;
      }
      if (typeof this.audioPlayer[avatarData.asset_id] != "undefined" && this.audioPlayer[avatarData.asset_id] != null) {
        this.audioPlayer[avatarData.asset_id].pause()
      }
      const parent = document.getElementById(this.avatarElemName);
      const existNode = document.getElementById(avatarData.asset_id);
      if (avatarData.spritesheetId != null) {
        if (existNode) {
          parent.removeChild(existNode);
          clearInterval(this[avatarData.asset_id]);
        }
      } else {
        const parent = document.getElementById(this.avatarElemName);
        const existNode = document.getElementById(avatarData.asset_id);
        if (existNode) {
          parent.removeChild(existNode);
        }
      }
    });
  }

  /**
   * checks if js is already downloaded or and downloads the js
   * @param {object} avatar 
   */
  async checkFileExistsAndDownload(avatar, showAssetAppliedSuccessfullyToast, assetItem, asset) {
    if (typeof this['destroyRegisteredAssetEvent'][avatar.asset_id] != "undefined" && this['destroyRegisteredAssetEvent'][avatar.asset_id] != null) {
      this.destroyRegisteredAssetEvent[avatar.asset_id]();
      this.destroyRegisteredAssetEvent[avatar.asset_id] = null;
    }
    let parent = document.getElementById(this.avatarElemName);
    const existNode = document.getElementById(avatar.asset_id);
    if (existNode) {
      //temp
      //parent.removeChild(existNode);
    }
    let a, item;
    // this.state.manifest.map((obj, key) => {
    a = 'canvs_' + avatar.asset_id;
    var node3 = document.createElement("div")

    node3.id = avatar.asset_id;
    node3.style.height = "100%";
    node3.style.width = "100%";
    node3.style.position = "absolute";
    node3.draggable = false;
    node3.dataset.zindex = avatar.zIndex;
    // obj.zIndex ? node2.style.zIndex = obj.zIndex : null;
    var node2 = document.createElement("div");
    node2.id = avatar.asset_id + '_anim_id';
    node2.style.height = "100%";
    node2.style.width = "100%";
    node2.style.position = "absolute";
    node2.draggable = false;
    avatar.zIndex ? node2.style.zIndex = avatar.zIndex : null;
    node3.appendChild(node2);
    var node = document.createElement("canvas");
    node.id = a;
    node.style.height = "100%";
    node.style.width = "100% ";
    node.style.position = "absolute";
    node.style.display = 'block';
    node.draggable = false;
    node2.appendChild(node);

    var node1 = document.createElement("div");
    node1.id = avatar.asset_id + '_overlay_id';
    node1.style.height = "100%";
    node1.style.width = "100%";
    node1.style.overflow = 'hidden';
    node1.style.left = '0px';
    node1.style.display = 'block';
    node1.style.top = '0px';
    node1.style.position = "absolute";
    node1.draggable = false;

    node2.appendChild(node1);
    document.getElementById(this.avatarElemName).appendChild(node3);
    item = {
      loadTimeout: 300000,
      id: a,
      src: avatar.jsFile,
      lib: avatar.lib,
      asset: asset,
      assetItem: assetItem,
      anim_id: avatar.asset_id + '_anim_id',
      overlay_id: avatar.asset_id + '_overlay_id',
      images: avatar.images,
      // avatarSprite: avatar.type,
      asset_id: avatar.asset_id,
      showAssetAppliedSuccessfullyToast: showAssetAppliedSuccessfullyToast

    }
  
    var preload = new createjs.LoadQueue(false);
    preload.on("fileload", (e) => this.handleFileLoad(e));
    preload.on("error", (err, assetItem, asset) => this.handleError(err, assetItem, asset, avatar.jsFile));
    preload.loadManifest({ manifest: [item] }, true);
  }

  /**
   * when new pet is selected , destroying listners of previous pet
   */
  destroyRegisteredAssetEvents() {
    for (let key in this.destroyRegisteredAssetEvent) {
      if (this.destroyRegisteredAssetEvent.hasOwnProperty(key)) {
        if (this.destroyRegisteredAssetEvent[key] != null) {
          this.destroyRegisteredAssetEvent[key]();
          this.destroyRegisteredAssetEvent[key] = null;
        }
      }
    }
    this.state.intervals.forEach((id) => {
      clearInterval(this[id]);
    })
  }
  /**
 * renderCanvas;
 * this event appends the html to the main container using data driven by api
 * and also downloads the realted js for canvas drawing
 *
 * @param {avatar} object
 *
 */
  renderCanvas(avatar) {
    this.setState({ manifest: this.state['manifest' + avatar.spritesheetId] });
    this.checkFileExistsAndDownload(avatar)
  }

  /**
* handleImageLoad;
* events fires when the js is completly downloaded using preload
*
* @param {event} object
* @memberof renderCanvas
*/
  handleFileLoad = (event) => {
    let item = event.item;
    let data = {
      id: item.id,
      lib: item.lib,
      anim_id: item.anim_id,
      overlay_id: item.overlay_id,
      asset: item.asset,
      src: item.src,
      assetItem: item.assetItem,
      images: item.images,
      // avatarSprite: item.avatarSprite,
      asset_id: item.asset_id,
      showAssetAppliedSuccessfullyToast: item.showAssetAppliedSuccessfullyToast
    }
    this.downloadAssets(data)
  }

  /**
   * downloadAssets;
   * event downloads the necessary assets for drawing canvas based on libId and composition
   *
   * @param {data} object
   * @memberof handleFileLoad
   */
  downloadAssets(data) {
    let canvas_tree, anim_container_tree_tree, dom_overlay_container_tree;
    canvas_tree = document.getElementById(data.id);
    anim_container_tree_tree = document.getElementById(data.anim_id);
    dom_overlay_container_tree = document.getElementById(data.overlay_id);
    data.libId = Object.keys(AdobeAn.compositions)[(Object.keys(AdobeAn.compositions).length - 1)];
    let comp = AdobeAn.getComposition(data.libId);
    delete AdobeAn.compositions
    createjs.MotionGuidePlugin.install();
    let nppvma_loader = new createjs.LoadQueue(false);
    let canvas_data = {
      comp: comp,
      lib: data.lib,
      asset: data.asset,
      assetItem: data.assetItem,
      src: data.src,
      canvas_tree: canvas_tree,
      showAssetAppliedSuccessfullyToast: data.showAssetAppliedSuccessfullyToast,
      dom_overlay_container_tree: dom_overlay_container_tree,
      anim_container_tree_tree: anim_container_tree_tree,
      asset_id: data.asset_id,
    }
    nppvma_loader.addEventListener("fileload", (evt) => { this.handleFileLoadTree({ evt, ...canvas_data }) }, false);
    nppvma_loader.addEventListener("complete", (evt) => { this.handleCompleteTree({ evt, ...canvas_data }) }, false);
    nppvma_loader.on("error", (err, asset) => this.handleError(err, data.assetItem, data.asset, data.src));
    let lib = comp.getLibrary();
    lib.properties.manifest && lib.properties.manifest.map((imageObject, index) => {
      imageObject.src = data.images[imageObject.src.split('/').pop().split('.')[0].replace(/ /g, '')];
      if (lib.properties.manifest.length === (index + 1)) {
        nppvma_loader.loadManifest(lib.properties.manifest, true);
      }
    })
  }

  /**
   * handleFileLoadTree;
   * event updates the required images from event result
   *
   * @param {data} object
   * @memberof downloadAssets
   */
  handleFileLoadTree = (data) => {
    let images = data.comp.getImages();
    if (data.evt && (data.evt.item.type == "image")) { images[data.evt.item.id] = data.evt.result; }
  }
  /**
   * Register the tick listener and returns remove event
   * @param {object} data 
   */
  // unregisterTickEvent(data) {
  //   createjs.Ticker.addEventListener("tick", data.stage_tree);
  //   return function() {
  //     createjs.Ticker.removeEventListener("tick", data.stage_tree);
  //   } 
  // }
  async unregisterTickEvent(data) {
    let cm = this
    await data.stage_tree.addChild(data.exportRoot_tree);
    await createjs.Ticker.addEventListener("tick", data.stage_tree);
    createjs.Ticker.useRAF = false;
    return async function () {

      data.lib = null;
      createjs.Ticker.removeEventListener("tick", data.stage_tree);
      await data.stage_tree.enableDOMEvents(false);
      await data.stage_tree.removeAllChildren();
      data.stage_tree.autoClear = true;
      await data.stage_tree.removeChild(data.exportRoot_tree);
      await data.exportRoot_tree.cache();
      data.exportRoot_tree = null;
      let root = document.getElementById(data.asset_id);
      if (root) {
        await root.remove();
      }
      data.stage_tree = null;
    }
  }

  /**
   * fnStartAnimation_tree;
   * event Registers the "tick" event listener.
   *
   * @param {data} object
   * @memberof handleCompleteTree
   */
  async fnStartAnimation_tree(data) {
    await data.stage_tree.addChild(data.exportRoot_tree);
    await createjs.Ticker.setFPS(data.lib.properties.fps);
    this.destroyRegisteredAssetEvent[data.asset_id] = await this.unregisterTickEvent.bind(this)(data);
    // createjs.Ticker.addEventListener("tick", data.stage_tree);
    this.loaderService.hide();
    if (data.showAssetAppliedSuccessfullyToast) {
      this.props.showAssetAppliedSuccessfullyToast(data.showAssetAppliedSuccessfullyToast)
    }

  }

  /**
   * makeResponsive;
   * event to support hidpi screens and responsive scaling.
   *
   * @param {isResp, respDim, isScale, scaleType, data}
   * @memberof handleCompleteTree
   */
  async makeResponsive(isResp, respDim, isScale, scaleType, data) {
    let lib = await data.comp.getLibrary();
    let lastW, lastH, lastS = 1;
    window.addEventListener('resize', resizeCanvas);
    await resizeCanvas();
    function resizeCanvas() {
      let w = lib.properties.width, h = lib.properties.height;
      let iw = window.innerWidth, ih = window.innerHeight;
      let pRatio = window.devicePixelRatio || 1, xRatio = iw / w, yRatio = ih / h, sRatio = 1;
      if (isResp) {
        if ((respDim == 'width' && lastW == iw) || (respDim == 'height' && lastH == ih)) {
          sRatio = lastS;
        }
        else if (!isScale) {
          if (iw < w || ih < h)
            sRatio = Math.min(xRatio, yRatio);
        }
        else if (scaleType == 1) {
          sRatio = Math.min(xRatio, yRatio);
        }
        else if (scaleType == 2) {
          sRatio = Math.max(xRatio, yRatio);
        }
      }
      data.canvas_tree.width = w * pRatio * sRatio;
      data.canvas_tree.height = h * pRatio * sRatio
      data.canvas_tree.style.width = data.dom_overlay_container_tree.style.width = data.anim_container_tree_tree.style.width = '100%';
      data.canvas_tree.style.height = data.anim_container_tree_tree.style.height = data.dom_overlay_container_tree.style.height = '100%';
      data.stage_tree.scaleX = pRatio * sRatio;
      data.stage_tree.scaleY = pRatio * sRatio;
      lastW = iw; lastH = ih; lastS = sRatio;
      // data.stage_tree.tickOnUpdate = false;
      // data.stage_tree.update();
      // data.stage_tree.tickOnUpdate = true;

    }
  }

  /**
   * handleCompleteTree;
   * event loads the canvas composition, makes it responsive
   *
   * @param {data} object
   * @memberof downloadAssets
   */
  async handleCompleteTree(data) {
    try {
      let lib = await data.comp.getLibrary();
      let ss = await data.comp.getSpriteSheet();
      let queue = data.evt.target;
      let ssMetadata = lib.ssMetadata;
      for (var i = 0; i < ssMetadata.length; i++) {
        ss[ssMetadata[i].name] = new createjs.SpriteSheet({ "images": [queue.getResult(ssMetadata[i].name)], "frames": ssMetadata[i].frames })
      }
      let canvas_tree = {
        exportRoot_tree: new lib[data.lib](),
        stage_tree: new lib.Stage(data.canvas_tree),
        asset_id: data.asset_id,
        showAssetAppliedSuccessfullyToast: data.showAssetAppliedSuccessfullyToast
      }
      await this.makeResponsive(false, 'both', false, 1, { stage_tree: canvas_tree.stage_tree, ...data });
      await AdobeAn.compositionLoaded(lib.properties.id);
      await this.fnStartAnimation_tree.bind(this)({ lib, ...canvas_tree });
    }
    catch (e) {
      this.loaderService.hide();
      console.log(' JS Lib name error '+ '\n' + 'Object Info Id: ' 
      + data.assetItem.obj_info_id + '\n' + 'File Path: ' +  data.src)
    }
  }

  /**
     * handleError;
     * event fires when an error occurs in downloading the required canvas js.
     */
    handleError(err, assetItem, asset, filepath) {
      this.loaderService.hide();
      console.log('JS error '+ '\n' + 'Object Info Id: ' 
      + assetItem.obj_info_id + '\n' + 'File Path: ' +  filepath)
    }
 
  /**
      * imageAssetLoad;
      * select whether it is Spritesheet or Normal png
      *
      * @param {avatar} object
      *
      */
  imageAssetLoad = (avatar) => {
    $('#nppvma_petViewMainContent').addClass('nppvma-loader')
    if (avatar.spritesheetId != null) {
      this.renderCanvas(avatar)
    } else {
      $(`#${avatar.asset_id}`).find('img').attr('src', avatar.imgUrl);
      $('#nppvma_petViewMainContent').removeClass('nppvma-loader')
    }
  }

  componentWillUnmount() {
    this.destroyRegisteredAssetEvents();
  }

  getPet() {
    this.props.UserPets.map((pet) => {
      if (pet.isActive) {
        this.setState({ pet_url: pet.image });
      }
    })
  }

  toggleFiltersPopup = () => {
    this.setState({
      showFiltersPopup: !this.state.showFiltersPopup
    })
  }

  createAnimateObject(assetItem, manifest, showAssetAppliedSuccessfullyToast, asset) {
     // apply zone restrict
     if(asset && asset.zones_restrict){
      this.handleZoneRestrict(asset, true);
    }
    
    let _that = this
    let finalObject = {};
    finalObject.asset_id = assetItem.asset_id
    finalObject.zIndex = ZoneDepth[assetItem.zone_id]
    finalObject.images = {}
    manifest.cpmanifest.assets[0].asset_data.map((manifest_asset, index) => {
      if (manifest_asset.file_ext === 'js') {
        finalObject.jsFile =  (this.props.customiseData && this.props.customiseData.assetsDomain) ? `${this.props.customiseData.assetsDomain}/${manifest_asset.url}` : `http://images50.neopets.com/${manifest_asset.url}`;
        finalObject.lib = manifest_asset.url.split('/').pop().split('.')[0].replace(/ /g, '').replace(/-/g, '');
        if (finalObject.lib.match(/^\d/)) {
          finalObject.lib = '_' + finalObject.lib;
        }
      } else if (manifest_asset.file_ext === 'json') {
        finalObject.jsonFile = manifest_asset.url;
      } else if (manifest_asset.file_ext === 'mp3') {
        finalObject.audioFile = manifest_asset.url;
      } else if (manifest_asset.file_ext === 'svg') {
        manifest_asset.url = manifest_asset.url.replace('svg', 'png');
        finalObject.static = (this.props.customiseData && this.props.customiseData.assetsDomain) ? `${this.props.customiseData.assetsDomain}/${manifest_asset.url}` : `http://images50.neopets.com/${manifest_asset.url}`;
      } else {
        finalObject.images[manifest_asset.url.split('/').pop().split('.')[0].replace(/ /g, '')] = (this.props.customiseData && this.props.customiseData.assetsDomain) ? `${this.props.customiseData.assetsDomain}/${manifest_asset.url}` : `http://images50.neopets.com/${manifest_asset.url}`;
        
      }
      if (index === (manifest.cpmanifest.assets[0].asset_data.length - 1)) {
        // https://www.kozco.com/tech/piano2-CoolEdit.mp3
        if (finalObject.audioFile) {
          this.audioPlayer[assetItem.asset_id] = new Audio(finalObject.audioFile);
          this.audioPlayer[assetItem.asset_id].play();
          this.audioPlayer[assetItem.asset_id].loop = true;
          } else if (finalObject.jsFile) {
            _that.checkFileExistsAndDownload(finalObject, showAssetAppliedSuccessfullyToast, assetItem, asset)
          } else if (finalObject.static) {
            _that.applyStaticAsset(finalObject, showAssetAppliedSuccessfullyToast, asset)
          } else {
            finalObject.static = finalObject.images[Object.keys(finalObject.images)]
            _that.applyStaticAsset(finalObject, showAssetAppliedSuccessfullyToast, asset)
          }
        }
      })
    }

  downloadManifestAndCreateAnimateObject(isBodyPart, assetRegistry, asset, showAssetAppliedSuccessfullyToast) {
    assetRegistry.map((assetItem) => {
      this.loaderService.show();
      axios.get(assetItem.manifest).then(response => {
        if (response.data) {
          this.createAnimateObject(assetItem, response.data, showAssetAppliedSuccessfullyToast, asset)
        } else {
          if (!isBodyPart) {
            // this.props.toggleConformationCloseItem(asset);  
          }
        }
      }).catch((error) => {
        console.log('manifest error '+ '\n' + 'Object Info Id: ' 
        + asset.obj_info_id + '\n' + 'File Path: ' +  assetItem.manifest)
        this.loaderService.hide();
        if (!isBodyPart) {
          // this.props.toggleConformationCloseItem(asset);
        }
        console.log('If requestdata failed ' + error);
      });
    })
  }

  /**
   *  Handle zone restrictNewest First
   * @param {*} asset 
   * @param {boolean} isApplying 
   */
  handleZoneRestrict(asset, isApplying){
    let zones_restrict_length = asset.zones_restrict.length;
    for(let i = 0; i < zones_restrict_length; i++) {
      if(asset.zones_restrict[i] === '1'){
        let restrictedzoneElement = this.getElementByAttribute('data-zindex', ZoneDepth[i+1], document.getElementById(this.avatarElemName))
        if(restrictedzoneElement){
          if(isApplying){
            restrictedzoneElement.style.display = 'none';
          }else{
            restrictedzoneElement.style.display = 'block';
          }
        }
      }
    }
  }

  /**
   * returns the dom element based on z-index
   * @param {string} attr 
   * @param {string} value 
   * @param {string} root 
   */
  getElementByAttribute(attr, value, root) {
    root = root || document.body;
    if(root.hasAttribute(attr) && root.getAttribute(attr) == value) {
        return root;
    }
    var children = root.children, 
        element;
    for(var i = children.length; i--; ) {
        element = this.getElementByAttribute(attr, value, children[i]);
        if(element) {
            return element;
        }
    }
    return null;
}

  render() {
    return (
      <React.Fragment>
        <div id="nppvma_petViewMainContent" className="nppvma-petView-main-content npcma-disableselect npcma-disabledrag" >
          <div className="nppvma-avatarlayers npcma-disableselect npcma-disabledrag" id={this.avatarElemName}>

          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default CustomisePet;
