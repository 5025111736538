import React, { Component } from 'react';
import CustomisePet from './customizePet';
import { API_METHODS } from '../../services/api_methods';
import { apiService } from '../../services/api_service';

class Customise extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appliedAssets: [],
            petBodyParts: [],
            customiseData: this.props.customiseData
        };
    }


    componentDidMount() {       
        this.api_customPetViewData();
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.customiseData !== prevState.customiseData) {
            return { customiseData: nextProps.customiseData };
        }
        else return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.customiseData !== this.props.customiseData) {
            //Perform some operation here
            this.setState({ customiseData : this.props.customiseData },()=>  this.api_customPetViewData());
          
        }
    }
    /**
     * Fetches the custom pet editor data
     */
    async api_customPetViewData() {
        const payload = {
            method: API_METHODS.Custom_Pet_View_Data,
            username: this.props.customiseData.username,
            petname: this.props.customiseData.petname,
            petsci: this.props.customiseData.petsci
        };
        await apiService.post({
            baseDomain: this.props.customiseData.baseDomain,
            payLoad: payload,
            loaderElem: this.props.customiseData.loaderElem
        }).then(async (response) => {
            if (response.data) {
                var avatarElemName = "nppvma_petViewAvatarLayer" + this.props.customiseData.petname; 
                const petViewElement = document.getElementById(avatarElemName);
                if (petViewElement) {
                    petViewElement.innerHTML = '';
                    this.refs.nppvma_refPetView.destroyRegisteredAssetEvents()
                }

                let biology_by_zone = response.data.viewdata && response.data.viewdata.custom_pet && response.data.viewdata.custom_pet.biology_by_zone ? response.data.viewdata.custom_pet.biology_by_zone : {};

                let petBodyParts = []
                await Object.keys(biology_by_zone).forEach((key) => {
                    petBodyParts.push(biology_by_zone[key]);
                });

                const closet_items = response.data.viewdata.closet_items ? response.data.viewdata.closet_items : {}
                const object_info_registry = response.data.viewdata.object_info_registry ? response.data.viewdata.object_info_registry : {}
                let _closestAssets = [];
                let _appliedAssets = [];
                await Object.keys(closet_items).forEach((key) => {
                    const objId = closet_items[key].obj_info_id
                    const objInfo = object_info_registry[objId]
                    const finalObj = { ...closet_items[key], ...objInfo }
                    if (typeof finalObj.assets_by_zone !== 'undefined' && finalObj.assets_by_zone != '') {
                        Object.keys(finalObj.assets_by_zone).forEach((key1) => {
                            const temp = key1
                            delete finalObj.assets_by_zone
                            finalObj.assets_by_zone = temp
                        })
                    }
                    //if (typeof finalObj.applied_to !== 'undefined'){// && finalObj.applied_to !== this.props.customiseData.petname) {
                        //finalObj.asset_registry = []
                        //_closestAssets.push(finalObj)
                    //} else {
                        finalObj.asset_registry = []
                        _appliedAssets.push(finalObj)
                    //}
                });
                _closestAssets = _closestAssets.reduce((accum, val) => {
                    let dupicateIndex = accum.findIndex(arrayItem => arrayItem.obj_info_id === val.obj_info_id);
                    if (dupicateIndex === -1) {
                        accum.push({
                            count: 1,
                            closetObjIds: [val.closet_obj_id],
                            ...val
                        });
                    } else {
                        accum[dupicateIndex].count++;
                        if (val.applied_to === '') {
                            accum[dupicateIndex].closetObjIds.push(val.closet_obj_id)
                        }
                    }
                    return accum;
                }, []);

                if (response.data && response.data.pbitems && response.data.pbitems.length) {
                    _closestAssets.map((item) => {
                        item.isPbItem = (response.data.pbitems.indexOf(`${item.obj_info_id}`) > -1);
                    })
                }
                let object_asset_registry = response.data.viewdata.object_asset_registry ? Object.values(response.data.viewdata.object_asset_registry) : {}
                for (let i = 0; i < object_asset_registry.length; i++) {
                    for (let j = 0; j < _closestAssets.length; j++) {
                        if (object_asset_registry[i].obj_info_id === _closestAssets[j].obj_info_id) {
                            _closestAssets[j].asset_registry.push(object_asset_registry[i])
                        }
                    }
                }
                for (let i = 0; i < object_asset_registry.length; i++) {
                    for (let j = 0; j < _appliedAssets.length; j++) {
                        if (object_asset_registry[i].obj_info_id === _appliedAssets[j].obj_info_id) {
                            _appliedAssets[j].asset_registry.push(object_asset_registry[i])
                        }
                    }
                }

                this.setState({
                    petBodyParts: petBodyParts,
                    appliedAssets: JSON.parse(JSON.stringify(_appliedAssets)),
                }, () => {
                    this.refs.nppvma_refPetView.downloadManifestAndCreateAnimateObject(true, this.state.petBodyParts, {});
                    this.state.appliedAssets.map((asset) => {
                        this.refs.nppvma_refPetView.downloadManifestAndCreateAnimateObject(false, asset.asset_registry, asset);
                    })
                })
            } else {
                alert('Please check your network connectivity');
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="nppvma-petView">
                    <CustomisePet ref="nppvma_refPetView" customiseData= {this.props.customiseData}
                    />
                </div>
            </React.Fragment>
        );
    }
}


export default Customise
