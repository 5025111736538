import axios from 'axios';
import { LoaderService } from '../loader_service';
class Server {

    axiosOptions = {
        timeout: 10000,
        withCredentials: true,
    };

    ContentHeaders = {
        Json: 'application/json',
        FormData: 'multipart/form-data',
        urlEncoded: 'application/x-www-form-urlencoded; charset=UTF-8',
        Plain: 'text/plain',
    };

    BaseDomain = {
        Api: process.env.baseDomain ? process.env.baseDomain : 'http://dev.neopets.com/jsapiservices/jsapiservices.phtml',
    };

    loaderService = {};

    constructor() {
        this.loaderService = new LoaderService('nppvma_loader1');
        axios.interceptors.response.use((response) => {
            this.loaderService.hide();
            if (response.data && response.data.status > 300) {
               // console.log('response.data: '.response.data);
            }

            return response;
        }, (error) => {
            this.loaderService.hide();
            if (error.response) {
                if (error.response.status === 403 || error.response.status === 401) {
                    // logout
                }
            }

            if (error.message === 'timeout of 10000ms exceeded') {
                error.message = 'Request has been timed out. Please try again';
            }

            if (error.message === 'Network Error') {
                if (navigator.onLine) {
                    error.message = 'We are unable to reach the server. Please try later.';
                } else {
                    error.message = 'Please check your internet connection.';
                }
            }

            return new Promise((resolve, reject) => {
                if (error.response) {
                    reject(error.response.data);
                } else {
                    reject(error);
                }
            });
        });
    }


    getHeadersByType(headerType) {
        const data = {};
        switch (headerType) {
            case this.ContentHeaders.Json:
                {
                    data['Content-Type'] = 'application/json';
                    break;
                }
            case this.ContentHeaders.Plain:
                {
                    data['Content-Type'] = 'text/plain';
                    break;
                }
            case this.ContentHeaders.FormData:
                {
                    data['Content-Type'] = 'multipart/form-data';
                    break;
                }
            case this.ContentHeaders.urlEncoded:
                {
                    data['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
                    //    data['Access-Control-Allow-Origin'] = '*';
                    break;
                }
            default:
                data['Content-Type'] = 'application/json';
                break;
        }

        return data;
    }

    post = (data) => {
        if (!data.baseDomain) {
            data.baseDomain = this.BaseDomain.Api;
        }
        if (!data.headerType) {
            data.headerType = this.ContentHeaders.urlEncoded;
        }
        if (data.showLoader || data.showLoader === undefined) {
            this.loaderService.show();
        }

        if (data.headerType === this.ContentHeaders.urlEncoded) {
            const formData = new FormData();
            const payload = data.payLoad;
            for (const key in payload) {
                if (payload.hasOwnProperty(key)) {
                    if (typeof payload[key] !== 'object') {
                        formData.append(key, payload[key]);
                    } else {
                        formData.append(key, JSON.stringify(payload[key]));

                    }

                }
            }

            data.payLoad = formData;
        }

        return axios.post(data.baseDomain,
            data.payLoad, {
                timeout: this.axiosOptions.timeout,
                // transformRequest: this.axiosOptions.transformRequest,
                baseURL: data.baseDomain,
                headers: this.getHeadersByType(data.headerType),
            });
    }
}
export const apiService = new Server();