import React from 'react'
import './loader.scss'

const Loader = () => {
  return (
    <div className="nppvma-loading-text">
      <img src={'http://images.neopets.com/images/npcma/loading.png'} />
      <p className='loading'>Loading</p>
    </div>
  )
}


export default Loader
