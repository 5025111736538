export const ZoneDepth = {
    "1": 1,
    "2": 2,
    "3": 3,
    "4": 6,
    "5": 7,
    "6": 8,
    "7": 9,
    "8": 10,
    "9": 11,
   " 10": 12,
    "11": 13,
    "12": 14,
    "13": 15,
    "14": 17,
    "15": 18,
    "16": 19,
    "17": 20,
    "18": 21,
    "19": 22,
    "20": 23,
    "21": 24,
    "22": 25,
    "23": 26,
    "24": 28,
    "25": 29,
    "26": 30,
    "27": 31,
    "28": 32,
    "29": 33,
    "30": 34,
    "31": 35,
    "32": 36,
    "33": 37,
    "34": 38,
    "35": 41,
    "36": 39,
    "37": 40,
    "38": 42,
    "39": 43,
    "40": 44,
    "41": 45,
    "42": 46,
    "43": 47,
    "44": 49,
    "45": 50,
    "46": 48,
    "47": 51,
    "48": 4,
    "49": 5,
    "50": 16,
    "51": 27,
    "52": 52
}
